import {joinPaths, truncatePleasantly} from '../utils/index'
import * as PATHS from '../utils/paths'
import { Link } from 'gatsby'
import { Grid } from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const articleSnippetStyles = theme => ({
  root: {
    marginBottom: 32
  },
  image: {
    '& > img': {
      marginBottom: 0
    },
    width: "100%",
    height: "auto"

  },
  titleText: {
    marginBottom: 4,
    lineHeight: '32px',
    fontSize: 27,
    fontWeight: 600,
    letterSpacing: -.75
  },
  descriptionText: {
    lineHeight: '24px',
    fontSize: 16,
    marginBottom: 0
  }
})
const _ArticleSnippet2 = ({ title, path, node, classes, borderBgColor }) => {

  const description = truncatePleasantly(node.frontmatter.description,
    100, '...')

  const imageStyle = (borderBgColor) ? {
    border: `1px solid #AFB6BE`,
    borderBottom: `3px solid ${borderBgColor}`,
    borderRight: `2px solid ${borderBgColor}`
  } : {}

  return (
    <Link style={{
      boxShadow: `none`,
      textDecoration: 'none',
      color: 'inherit',
    }} to={path}>
      <Grid container className={classes.root}
            key={node.fields.slug}
            spacing={4}>

        <Grid item xs={12} sm={3}
              style={{ paddingBottom: 0 }}>
          <img className={classes.image}
               loading={"lazy"}
               style={imageStyle}
               alt={node.frontmatter.featuredImageAltText}
               src={ joinPaths([PATHS.CDN, node.fields.collection, node.fields.slug, node.fields.slug.slice(0,node.fields.slug.length-1) + "-preview.png" ])}/>
        </Grid>

        <Grid item xs={12} sm={9}>
          <header>
            <h3 className={classes.titleText}
                style={{ 'textDecoration': borderBgColor ? `2px solid ${borderBgColor} underline` : '' }}>

              {title}

            </h3>

          </header>
          <section>
            <p className={classes.descriptionText + ' ' + 'sans-serif'}>
              {description}
            </p>
          </section>
        </Grid>

      </Grid></Link>)
}
export const ArticleSnippet2 = withStyles(articleSnippetStyles)(_ArticleSnippet2)