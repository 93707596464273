import React from 'react'
import { graphql, Link } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LayoutInner from '../components/layoutInner'
import * as PATHS from '../utils/paths'
import { ArticleSnippet2 } from '../components/ArticleSnippet2'
import {joinPaths} from "../utils";

const blogIndexStyles = theme => ({
    root: {},
    subheroText: {
        fontSize: 21,
        lineHeight: "32px",
        fontWeight: 400,
        marginBottom: 16
    },
    postsSection: {
        marginTop: 16
    }
});

const _BlogIndex = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={"3iap | Human-Centered Data Design"}/>

            <LayoutInner>

                <h1
                style={{
                    fontSize: 31,
                    lineHeight: "40px",
                    fontWeight: 600,
                    marginBottom: 16,
                    letterSpacing: -1
                }}
                >
                    3iap helps clients research, design, and develop psychologically effective, equitable, and humanistic data visualization.
                </h1>
                <p className={classes.subheroText}>
                    3iap's approach to data communication is outcome-driven, centered on thoughtful analysis, and deeply rooted
                    in the science of visualization.
                </p>
                <p className={classes.subheroText}>
                    Learn <Link to={PATHS.ABOUT_PAGE}>about 3iap here</Link>,
                    see an overview of <Link to={PATHS.SERVICES_PAGE}>3iap’s services here</Link>,
                    or even better, send an email to <a href={"mailto:hi@3iap.com"}>hi@3iap.com</a> to get in touch.
                </p>

                <br/><hr/><br/>

                <div className={classes.postsSection}>
                    <h2>Research & Writing</h2>
                    <p>The essays below highlight
                        examples of psychologically effective data design.
                        They include empirical findings from our own work and others',
                        each with a different lens on the surprising ways that data can make a difference.</p>
                    <br/><br/>
                    {posts.filter(p=>!p.node.frontmatter.hide)
                        .filter(p=> p.node.fields.collection === `blog`)
                        .map(({node},i) => {
                        const title = node.frontmatter.title || node.fields.slug
                        return (<ArticleSnippet2 key={i} path={node.fields.slug} title={title} node={node}/>)
                    })}
                </div>

                <br/><hr/><br/>

                <div className={classes.postsSection}>
                    <h2>Work Samples</h2>
                    <p>These are projects we're proud of, including client work and experimental designs.</p>
                    <br/>
                    {posts.filter(p=>!p.node.frontmatter.hide)
                        .filter(p=> p.node.fields.collection === `work`)
                        .map(({node},i) => {
                            const title = node.frontmatter.title || node.fields.slug
                            return (
                                <ArticleSnippet2 key={i} title={title} node={node}
                                                 path={"/work" + node.fields.slug}
                                                 borderBgColor={node.frontmatter.featuredImageBGColor}
                                />)
                        })}
                </div>

                <br/><hr/><br/>

                <img style={{maxWidth: "100%"}} src={joinPaths([PATHS.CDN, "images/3iap-featured-in.png"])}/>

                <br/><br/><hr/><br/>

                <img style={{maxWidth: "100%"}} src={joinPaths([PATHS.CDN, "images/3iap-logo-wall.png"])}/>

            </LayoutInner>

        </Layout>
    )
}

export const BlogIndex = withStyles(blogIndexStyles)(_BlogIndex)
export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            collection
          }
          frontmatter {
            title
            description
            readTime
            hide
            featuredImageAltText
            featuredImageBGColor
          }
        }
      }
    }
  }
`
